import { MdDomain } from "react-icons/md";

export default function Contacts() {
    return (
        <div>
            <p style={{
                color: 'white'
            }}>
                <MdDomain /> Contacts
            </p>
        </div>
    )
}